import { render, staticRenderFns } from "./agentcollection.vue?vue&type=template&id=32edf330&scoped=true"
import script from "./agentcollection.vue?vue&type=script&lang=js"
export * from "./agentcollection.vue?vue&type=script&lang=js"
import style0 from "./agentcollection.vue?vue&type=style&index=0&id=32edf330&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32edf330",
  null
  
)

component.options.__file = "agentcollection.vue"
export default component.exports