<template>
  <div class="box">
    <div class="search">

      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="账户信息：" prop="businessStatus">
              <el-input v-model="queryInfo.name" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商家信息：" prop="businessStatus">
              <el-input v-model="queryInfo.name" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="交易时间：" prop="industryType">
              <el-date-picker @change="change" v-model="valuechange" style="width: 100%;" type="daterange"
                start-placeholder="开始日期" end-placeholder="结束日期" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="display: flex; justify-content: flex-end">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="tab">
      <div class="add">
        <el-button type="primary" @click="added">新增</el-button>
        <el-button type="primary" @click="added">导出</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" show-summary height="calc(100vh - 380px)" border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">
        <el-table-column prop="id" label="序号" width="160" align="center">

        </el-table-column>
        <el-table-column prop="businessName" label="商家名称" align="center">
        </el-table-column>
        <el-table-column prop="allowPayoutsNum" label="商家账号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="name" label="订单数" align="center">

        </el-table-column>
        <el-table-column prop="name" label="订单总额" align="center">

        </el-table-column>
        <el-table-column prop="payoutsTotalNum" label="商品实收" width="100" align="center">

        </el-table-column>
        <el-table-column prop="linkPhone" label="平台外卖抽佣" width="150" align="center">

        </el-table-column>
        <el-table-column prop="scope" label="平台团购抽佣" align="center">

        </el-table-column>
        <el-table-column prop="industryType" label="平台到店扫码抽佣" align="center">

        </el-table-column>
        <el-table-column prop="industryType" label="门店收款金额" align="center">

        </el-table-column>
        <el-table-column prop="businessStatus" label="结算金额" align="center">

        </el-table-column>
        <el-table-column prop="licenceImg" label="门店退款金额" align="center">
          <!-- <template slot-scope="scope">
            <img style="width: 100px;height: 100px;" :src="scope.row.licenceImg" alt="">
          </template> -->
        </el-table-column>
        <el-table-column prop=" " label="操作" width="200" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="deta(scope.row.id)">编辑</el-button>
            <el-button type="text" @click="deta(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog title="新增商家收款账户" :visible.sync="showDialog" width="600px" height="1000px">
      <div class="dia">
        <el-form ref="form" :model="form" label-width="120px" label-position="left">
          <el-row>
            <el-col span="23">
              <el-form-item label="店铺名称">
                <el-input v-model="from.orderSq" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="23">
              <el-form-item label="店铺账号">
                <el-input v-model="from.orderSq" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="23">
              <el-form-item label="收款银行">
                <el-input v-model="from.orderSq" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="23">
              <el-form-item label="收款卡号">
                <el-input v-model="from.orderSq" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="23">
              <el-form-item label="持卡人姓名">
                <el-input v-model="from.orderSq" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="23">
              <el-form-item label="银行预留手机号">
                <el-input v-model="from.orderSq" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="but">
          <el-button size="medium" @click="showDialog = false">取消 </el-button>
          <el-button size="medium" type="primary" @click="bc()">保存 </el-button>
        </div>
      </div>
    </el-dialog>

  </div>

</template>

<script>
export default {
  data() {
    return {
      showDialog: false,


      tableData: [],
      total: 0,
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {

        }
      },
      valuechange: '',
      form: {},
      tapshow: 0,
      options: [{
        value: '1',
        label: '店长'
      }, {
        value: '2',
        label: '员工'
      }],
      radio: '1',
      from: {}

    };
  },
  created() {
    this.getList()
  },
  mounted() {

  },
  methods: {
    change() {
      console.log(this.valuechange);
      this.from.startDate = this.newDate(this.valuechange[0])
      this.from.endDate = this.newDate(this.valuechange[1])
    },
    newDate(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    //获取数据
    getList() {
      var that = this;
      that.$http.post("/transaction/list", that.queryInfo).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
    search() {
      this.queryInfo.condition = this.form
      this.getList()
    },
    reset() {
      this.form = {}
      this.queryInfo.condition = {}
      this.getList()
    },
    deta(id) {
      this.showDialog = true
    },
    added() {
      this.showDialog = true
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 20px 20px 20px;

    .search-top {
      width: 1540px;
      height: 66px;
      padding: 2px 2px 2px 2px;
      background-color: rgba(30, 152, 215, 0.14);

      display: flex;
      align-items: center;
      margin-bottom: 50px;
      cursor: pointer;

      div {
        width: 150px;
        text-align: center;
      }

      .active {
        border-bottom: 2px solid red;
      }
    }
  }

  .finance {
    height: 132px;
    padding: 2px 2px 2px 2px;
    border-radius: 5px;
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 16px;
    justify-content: space-around;

    .fin-box {
      display: flex;

      .fin-li {
        margin-left: 15px;
      }
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px
  }

  .dia {
    .but {
      display: flex;
      justify-content: space-between;
      padding: 30px 200px;
    }

    .diali {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .liname {
        width: 100px;
      }

      input {
        height: 30px;
      }
    }

  }
}
</style>